//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.title {
  font-size: 20px;
  color: #4a4a4a;
  line-height: 24px;
  font-family: San-Francisco, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
}
