//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.inputWrapper {
  display: grid;
  grid-column-gap: 10px;
}

.inputSingleColumnWrapper {
  grid-template-columns: 1fr;
}

.inputDoubleColumnWrapper {
  grid-template-columns: 190px 1fr;
}

.main {
  grid-template-columns: 100px 1fr;
}

.long {
  grid-template-columns: 285px 1fr;
}

.single {
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
}


@media (max-width: 570px) {
  .main, .long, .inputDoubleColumnWrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
}
