//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.FormTable {
  .tableWrapper {
    width: 100%;
    overflow-x: auto;
  }

  table {
    textarea {
      width: 100%;
      box-sizing: border-box;
      color: #9b9b9b;
      font-family: San-Francisco-Text, sans-serif;
    }
    thead {
      color: #4a4a4a;
      font-family: San-Francisco-Text, sans-serif;
      line-height: 14px;
      font-size: 12px;
      letter-spacing: 0.5px;
    }
    th {
      border-top: 1px solid #f1efee;
      border-bottom: 1px solid #f1efee;
      padding: 20px 0 7px 7px;
    }
    th,
    td {
      text-align: left;
      vertical-align: top;
    }
    td {
      padding: 5px;
    }
    td {
      border-bottom: 1px solid #f1efee;
    }
  }

  .add-btn {
    color: #4a4a4a;
    text-decoration: underline;
    margin-top: 8px;
    line-height: 14px;
    font-size: 12px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
}
