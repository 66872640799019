//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.DataListAsync {
  position: relative;
  .input {
    position: relative;
    display: flex;
    align-items: center;
    height: 38px;
    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    &.borderless {
      border: 1px solid transparent;
      border-radius: 0;
    }
    &.transparent {
      background-color: transparent;
    }

    .value {
      flex: 1 1;
      align-items: center;
      padding: 0 12px;
      font-size: $middle-size;
      color: $primary-color;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.empty {
        color: #9b9b9b;
      }
    }
    .xIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 1;
      margin-right: 10px;

      img {
        width: 6px;
        height: 6px;
      }
    }
  }
  .popup {
    position: absolute;
    left: 0;
    top: 38px;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    background-color: #F1EFEE;
    box-shadow: 0px 2px 23px #E4E6E9;
    input{
      width: 264px;
    }
    .search {
      position: relative;
      height: 42px;
      padding: 2px;
      background-color: white;
      .spinner {
        position: absolute;
        right: 0;
        top: 0;
        width: 42px;
        height: 42px;
      }
    }

    .option {
      display: flex;
      align-items: center;
      height: 38px;
      background-color: white;
      margin-bottom: 1px;
      font-size: 13px;
      color: #4a4a4a;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 12px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: #f5f4f3;
      }
    }
  }
}
