//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.App {
  background-color: #F5F5F5;
  height: 100%;
}

#scrollHere {
  .multi-select {
    .dropdown-heading {
      border-color: rgb(255, 0, 0) !important;
    }
  }
}
