//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.TextArea {
  width: 100%;
  height: 89px;
  font-size: 12px;
  background-color: #fafafa;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color:#9B9B9B;
  font-family: San-Francisco-Text, sans-serif;
  resize: none;
  padding: 10px;
  &:disabled {
    cursor: not-allowed;
  }
  &::placeholder {
    color: #9b9b9b;
  }
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
  &.borderlessWhenDisabled:disabled {
    border: 1px solid transparent;
  }
}
