//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;



.formSubmission{
    margin: 65px auto 25px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    & * {
      box-sizing: border-box;
    }

   .formSubmission-wrapper{
     box-sizing: border-box;
     width: 615px;
     background:#FFFFFF;
     border: 1px solid #F1EFEE;
     padding: 73px 80px 0  80px;
     .submission-title{
       font-size: 24px;
       color: #4A4A4A;
       font-family:  San-Francisco, -apple-system, BlinkMacSystemFont,
       "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
     }

    .formBirth-wrapper{
      margin-top: 15px;
    }

     .submission-description{
       margin-top: 24px;
       font-family:  San-Francisco-Text, -apple-system, BlinkMacSystemFont,
       "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
       font-size: 12px;
       line-height: 18px;
       color: #4A4A4A;
       ul{
         padding: 0;
         margin-left: 15px;
       }
       strong{
         display: block;
       }
     }

     .submission-buttons{
       margin-top: 72px;
       button{
         font-family:  San-Francisco-Text, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
         background: #f1efee;
         border: none;
         font-size: 10px;
         line-height: 12px;
         letter-spacing: 1px;
         text-transform: uppercase;
         width: 200px;
         height: 40px;
         margin-right: 8px;
         cursor: pointer;
       }

       .submission-token{
        margin-top: 15px;
         margin-bottom: 15px;
         button{
           width: 115px;
           //height: 38px;
          //  margin-left: 8px;
          margin-top: 15px;
         }
       }
     }
   }
   .request-wrapper{
     button{
       margin-top: 72px;
       font-family:  San-Francisco-Text, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
       background: #f1efee;
       border: none;
       font-size: 10px;
       line-height: 12px;
       letter-spacing: 1px;
       text-transform: uppercase;
       width: 200px;
       height: 40px;
       margin-right: 8px;
       cursor: pointer;
     }
   }
 }

@media (max-width: 1270px) {
  .formSubmission{
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 615px) {
  .formSubmission{
    margin: 25px 0;
    margin-top: 80px;
    align-items: center;
    .formSubmission-wrapper{
      width: 100%;
      padding: 25px 30px 0 30px;
    }

    h1 {
      text-align: center!important;
    }
  }

  .submission-buttons_wrapper {
    display: grid!important;
    justify-content: center;
    grid-row-gap: 10px;
  }

  .submission-buttons {
    margin-top: 25px;
  }

}
